<script setup>
const { t } = useI18n({})
const bannerApiResponse = await useApi('/api/banner')

const bannerData = {
  title: bannerApiResponse.data.value?.title,
  description: bannerApiResponse.data.value?.intro,
  primaryButtonText: bannerApiResponse.data.value?.button1_text,
  primaryButtonLink: bannerApiResponse.data.value?.button1_url,
  variant: 'secondary',
  secondaryButtonText: bannerApiResponse.data.value?.button2_text,
  secondaryButtonLink: bannerApiResponse.data.value?.button2_url,
  image: bannerApiResponse.data.value?.image,
}
</script>

<template>
  <div class="w-screen overflow-hidden">
    <HomeBanner
      v-if="bannerData.title"
      v-bind="bannerData"
    />
    <HomePart1 />
    <HomePart2 />
    <HomePart3 />

    <ClientOnly>
      <HomeProductDiscounted />
    </ClientOnly>

    <ClientOnly>
      <HomeProductSpotlight />
    </ClientOnly>

    <ClientOnly>
      <HomeAdviceSpotlight />
    </ClientOnly>

    <ClientOnly>
      <HomeRecentProducts />
    </ClientOnly>

    <ClientOnly>
      <HomeSocialFeed />
    </ClientOnly>
    <GinoPopup
      :button="t('popup.advice_button')"
      class=""
      link="/advice"
      :text="t('popup.advice')"
    />
  </div>
</template>

<style>
body {
  overflow-x: hidden
}
</style>
